.cc-margin {
  margin: 2px;
}

.cc-padding {
  padding: 2px;
}
.cc-border {
  border: 1px solid #DDD;
}

.cc-selected {
  background-color: #AAA;
}

.cc-selector {
  cursor: pointer;
}
.cc-stickyCol {
  position: "sticky";
  left: 0;
  background: "white";
  box-shadow: "5px 2px 5px grey";
  border-right: "2px solid black";
  z-index:100;
}

.cc-nonSticky {
  z-index: 5;
}